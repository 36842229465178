$asset_base_url: #{"https://assets.dosh.cash"};
/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
}

/* Portrait */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  .imageContainer {
    display: none;
  }

  .noMobile {
    display: none;
  }

  .colHeader-last {
    text-align: center;
    padding-bottom: 30px;
  }

  .hotelListingPlaceholder {
    display: none;
  }
  .imageContainer {
    display: none;
  }

  .noMobile {
    display: none;
  }

  .colHeader-last {
    text-align: center;
    padding-bottom: 30px;
  }

  .hotelListingPlaceholder {
    display: none;
  }

  .white-clay {
    display: none;
  }
}

/* Landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .noMobile {
    display: none;
  }
  .mobileImage img {
    display: block;
  }
  .imageContainer {
    display: none;
  }

  .noMobile {
    display: none;
  }

  .colHeader-last {
    text-align: center;
    padding-bottom: 30px;
  }

  .hotelListingPlaceholder {
    display: none;
  }
  .hotelListingPlaceholder-mobile {
    display: none;
  }
  .cta_secondButton,
  .cta_secondButton:active,
  .cta_secondButton:hover {
    width: 100% !important;
    height: 44px;
    border-radius: 8px;
    background-color: #26176b;
    font-family: MarkOT;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    border: 0px;
  }

  .cta_firstButton,
  .cta_firstButton:active,
  .cta_firstButton:hover {
    margin-bottom: 33px;
    width: 100% !important;
    height: 40px;
    border-radius: 4px;
    border: solid 1.4px #cac2f2;
    background-color: #ffffff;
    font-family: MarkOT;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #a9a9a9;
  }
}

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
}

/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  .div-block-4.value-item-text-reverse {
    margin-left: 0px;
  }
  .value-props {
    padding-top: 50px;
  }

  .value-prop-item {
    margin-bottom: 40px;
  }

  .value_prop {
    margin-top: 50px;
  }

  .value_wrapper {
    margin-bottom: 50px;
  }

  .div-block-17 {
    margin-bottom: 50px;
  }

  .heading-4.callout-header {
    margin-top: 50px;
  }
  .propertyHeading {
    width: 60%;
  }
  .white-clay {
    display: none;
  }
}

/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .noMobile {
    display: none;
  }
  .div-block-4.value-item-text-reverse {
    margin-left: 0px;
  }
  .value-prop-item {
    margin-bottom: 40px;
  }
  .value-prop-item.reverse-value-item {
    flex-direction: row;
  }
  .text-block-6 {
    max-width: 100%;
    padding-top: 20px;
    padding-bottom: 0;
    color: #707070;
    line-height: 23px;
  }
  .heading-4 {
    max-width: 100%;
  }
  .value_prop {
    margin-top: 50px;
  }
  .value_wrapper {
    margin-bottom: 50px;
  }
}

/* ----------- iPhone XR ----------- */

@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
}
/* ----------- iPhone XS ----------- */

@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
}
/* ----------- iPhone XS Max ----------- */

/* Portrait */
@media only screen and (min-width: 414px) and (orientation: portrait) {
  .noMobile {
    display: none;
  }
  .div-block-4.value-item-text-reverse,
  .div-block-4 {
    margin-left: 0px;
    padding: 10px;
  }
  .value-prop-item {
    margin-bottom: 40px;
  }
  .value-prop-item.reverse-value-item {
    flex-direction: row;
  }

  .text-block-6 {
    max-width: 100%;
    padding-top: 20px;
    padding-bottom: 0;
    color: #707070;
    line-height: 23px;
  }
  .heading-4 {
    max-width: 100%;
  }
  .value_prop {
    margin-top: 50px;
  }
  .value_wrapper {
    margin-bottom: 50px;
  }
  .white-clay {
    display: none;
  }
}

/* Landscape */
@media only screen and (min-width: 896px) and (max-width: 1024px) and (orientation: landscape) {
  .noMobile {
    display: none;
  }
  .div-block-4.value-item-text-reverse,
  .div-block-4 {
    margin-left: 0px;
    padding: 10px;
  }
  .value-prop-item {
    margin-bottom: 40px;
  }
  .value-prop-item.reverse-value-item {
    flex-direction: row;
  }

  .text-block-6 {
    max-width: 100%;
    padding-top: 20px;
    padding-bottom: 0;
    color: #707070;
    line-height: 23px;
  }
  .heading-4 {
    max-width: 100%;
  }
  .value_prop {
    margin-top: 50px;
  }
  .value_wrapper {
    margin-bottom: 50px;
  }
}

/* ----------- Galaxy S5 ----------- */
@media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3) {
  .noMobile {
    display: none;
  }
  .div-block-4.value-item-text-reverse,
  .div-block-4 {
    margin-left: 0px;
    padding: 10px;
  }
  .value-prop-item {
    margin-bottom: 40px;
  }
  .value-prop-item.reverse-value-item {
    flex-direction: row;
  }

  .text-block-6 {
    max-width: 100%;
    padding-top: 20px;
    padding-bottom: 0;
    color: #707070;
    line-height: 23px;
  }
  .heading-4 {
    max-width: 100%;
  }
  .value_prop {
    margin-top: 50px;
  }
  .value_wrapper {
    margin-bottom: 50px;
  }
  .white-clay {
    display: none;
  }
}

/* Galaxy S5 portrait */
@media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
  .noMobile {
    display: none;
  }
  .div-block-4.value-item-text-reverse,
  .div-block-4 {
    margin-left: 0px;
    padding: 10px;
  }
  .value-prop-item {
    margin-bottom: 40px;
  }
  .value-prop-item.reverse-value-item {
    flex-direction: row;
  }

  .text-block-6 {
    max-width: 100%;
    padding-top: 20px;
    padding-bottom: 0;
    color: #707070;
    line-height: 23px;
  }
  .heading-4 {
    max-width: 100%;
  }
  .value_prop {
    margin-top: 50px;
  }
  .value_wrapper {
    margin-bottom: 50px;
  }
  .white-clay {
    display: none;
  }
}

/* ----------- Galaxy S6 ----------- */

/* Portrait and Landscape */
@media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 4) {
}

/* Portrait */
@media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 4) and (orientation: portrait) {
}

/* Landscape */
@media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 4) and (orientation: landscape) {
}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
}

/* ----------- iPad Pro 10.5" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 834px) and (max-device-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 834px) and (max-device-width: 834px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1112px) and (max-device-width: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
}

/* ----------- iPad Pro 12.9" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
}

/* ----------- Desktop/Laptop ----------- */
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
}
