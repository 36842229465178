$asset_base_url: #{"https://assets.dosh.cash"};
@font-face {
  font-family: 'MarkOT';
  src: url(#{$asset_base_url} + '/webapps/fonts/MarkOT.woff') format('woff');
}

@font-face {
  font-family: 'MarkOT';
  src: url(#{$asset_base_url} +  '/webapps/fonts/MarkOT-Medium.woff') format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'MarkOT';
  src: url(#{$asset_base_url} + '/webapps/fonts/MarkOT-Bold.woff') format('woff');
  font-weight: bold;
}

@font-face {
  font-family: 'MarkOT';
  src: url(#{$asset_base_url} + '/webapps/fonts/MarkOT-Light.otf') format('otf');
  font-weight: 300;
}