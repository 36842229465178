$asset_base_url: #{"https://assets.dosh.cash"};
input::-ms-clear {
  display: none;
}

main {
  height: 100%;
}

html,
body,
.body {
  min-height: 100% !important;
  height: 100%;
}

body {
  font-family: MarkOT, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  background: #f7f8f9;
  font-size: 16px !important;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  min-width: 320px;
  font-size: 14px;
  line-height: 1.4285em;
  color: #999;
}

.DayPicker-Day--today {
  font-weight: 500;
  color: #26176b;
}

@keyframes pulse_animation {
  0% {
    box-shadow: 0 0 0 0 rgba(105, 60, 175, 0.8);
  }
  100% {
    box-shadow: 0 0 0 200px rgba(105, 60, 175, 0);
  }
}

#pulse-wrapper {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
#pulse-wrapper #pulse {
  margin: auto;
}
#pulse-wrapper #pulse span {
  position: absolute;
  margin-top: -160px; /* = height / 2 */
  margin-left: -25px; /* = width / 2 */
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #693caf;
  animation: pulse_animation 1.3s infinite;
}
#pulse-wrapper #pulse span:nth-child(2) {
  animation-delay: 0.3s; /* Start the second wave with some delay */
}

.navbar-light .navbar-nav .nav-link {
  font-family: MarkOT;
}

@media only screen and (max-width: 600px) {
  .sc-bxivhb {
    width: 78% !important;
  }

  .cta_right {
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .cta_firstButton,
  .cta_firstButton:active,
  .cta_firstButton:hover {
    margin-bottom: 10px;
  }

  .cta_secondButton,
  .cta_secondButton:active,
  .cta_secondButton:hover {
    margin-bottom: 20px;
  }

  .bookBtn {
    width: 100%;
  }

  .best_value-container {
    padding: 0px;
  }

  .best_value-container-top {
    padding: 23px;
  }

  .hotelName {
    width: 80%;
    font-size: 14px;
  }

  .hotel-location {
    font-size: 13px;
  }

  .hotelListing {
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
  }

  .mobileImage img {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.27);
  }

  .searchCriteria_popover {
    padding: 32px 0px !important;
  }
}

.slide-animation-enter {
  overflow: hidden;
  height: 1px;
  opacity: 0.01;
}

.slide-animation-enter.slide-animation-enter-active {
  opacity: 1;
  transition: all 0.3s ease-out;
  transition-property: opacity, height;
}

.slide-animation-leave {
  overflow: hidden;
  opacity: 1;
  transition: all 0.3s ease-out;
  transition-property: opacity, height;
}

.slide-animation-leave.slide-animation-leave-active {
  height: 1px;
  opacity: 0.01;
}

.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.loading_box {
  border-radius: 6px;
  box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  padding: 30px 20px;
}

.DayPicker-Day--disabled,
.numGuestsDisabled {
  pointer-events: none;
  cursor: not-allowed;
}

.numGuestsDisabled {
  opacity: 0.2;
}

.btn-primary.disabled,
.btn-primary:disabled {
  border-radius: 10px;
  border: 1px solid #683caf;
  background: #683caf;
  opacity: 0.65;
  cursor: not-allowed;
}

.search_btn:disabled:hover {
  border-radius: 10px;
  border: 1px solid #683caf;
  background: #683caf;
  opacity: 0.65;
  cursor: not-allowed;
}

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) {
  .twoMonths {
    display: none;
  }
}

/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
}

@media screen and (min-device-width: 1200px) and (-webkit-min-device-pixel-ratio: 1) {
  .oneMonth {
    display: none;
  }
}

/* ----------- Retina Screens ----------- */
@media screen and (min-device-width: 1200px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  .oneMonth {
    display: none;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}
